 import type { ReportResult } from '@pigello/pigello-matrix';

import { ReportResultConfig } from "@pigello/pigello-matrix"



import { fetchGenericInstance, fetchGenericList } from '@/requests/fetching';
import type { getGenericInstanceProps, getGenericListProps } from '@/requests/types';

export const getReportResult = async (
  options: getGenericInstanceProps<ReportResult>
) => {
  return fetchGenericInstance<ReportResult>({
    ...options,
    config: ReportResultConfig,
  });
};

export const getReportResultList = async (
  options: getGenericListProps<ReportResult>
) => {
  return fetchGenericList<ReportResult>({
    ...options,
    config: ReportResultConfig,
  });
};
