import { ObjectKeys } from '@/lib/utils';
import type { ModelName } from '@pigello/pigello-matrix';
import { getCookie } from 'cookies-next';
import JSZip from 'jszip';
import { set } from 'lodash';
import { AVAILABLE_REPORTS } from './constants';
import type {
  IReportBody,
  IReportConfig,
  IReportFilterKind,
  IReportOperator,
  IReportOptions,
  IReportTypes,
} from './types';

export const getFormIdentifier = (idString: string) =>
  `reportbuilderform_${idString}`;

const getFilterKind = (colKey: string): IReportFilterKind => {
  switch (colKey) {
    case 'organization.company.id':
      return 'relation';
    case 'organization.company_invoicing.id':
      return 'relation';
    case 'organization.company_landlord.id':
      return 'relation';
    case 'objects.realestate.id':
      return 'relation';
    case 'accounts.tenant.realestates':
      return 'relation';
    case 'objects.basespace.source_identifier':
      return 'multiselect';
    case 'objects.basestructure.id':
      return 'structuremultiselect';

    default:
      return 'string';
  }
};

const getIsHighlighted = (colKey: string) => {
  switch (colKey) {
    case 'organization.company.id':
      return true;
    case 'objects.realestate.id':
      return true;
    case 'accounts.tenant.realestates':
      return true;

    default:
      return false;
  }
};

const getRelationModelName = (colKey: string): ModelName | undefined => {
  switch (colKey) {
    case 'organization.company_invoicing.id':
      return 'company';
    case 'organization.company_landlord.id':
      return 'company';
    case 'organization.company.id':
      return 'company';
    case 'objects.realestate.id':
      return 'realestate';
    case 'accounts.tenant.realestates':
      return 'realestate';
    default:
      return undefined;
  }
};

const getOperators = (colKey: string): IReportOperator[] => {
  switch (colKey) {
    case 'organization.company.id':
      return ['in'];
    case 'organization.company_invoicing.id':
      return ['in'];
    case 'organization.company_landlord.id':
      return ['in'];
    case 'objects.realestate.id':
      return ['in'];
    case 'accounts.tenant.realestates':
      return ['in'];
    case 'objects.basespace.source_identifier':
      return ['in'];
    case 'accounting.transaction.account':
      return ['in'];
    case 'objects.basestructure.id':
      return ['in'];

    default:
      return [];
  }
};

const getColumnFilter = (colKey: string, title: string) => {
  switch (colKey) {
    case 'organization.company.id':
      return {
        highlighted: getIsHighlighted(colKey),
        key: colKey,
        kind: getFilterKind(colKey),
        operators: getOperators(colKey),
        title: title,
        relationModelname: getRelationModelName(colKey),
      };
    case 'organization.company_invoicing.id':
      return {
        highlighted: getIsHighlighted(colKey),
        key: colKey,
        kind: getFilterKind(colKey),
        operators: getOperators(colKey),
        title: 'Aviserande bolag',
        relationModelname: getRelationModelName(colKey),
      };
    case 'organization.company_landlord.id':
      return {
        highlighted: getIsHighlighted(colKey),
        key: colKey,
        kind: getFilterKind(colKey),
        operators: getOperators(colKey),
        title: 'Hyresvärd',
        relationModelname: getRelationModelName(colKey),
      };
    case 'objects.realestate.id':
      return {
        highlighted: getIsHighlighted(colKey),
        key: colKey,
        kind: getFilterKind(colKey),
        operators: getOperators(colKey),
        title: title,
        relationModelname: getRelationModelName(colKey),
      };
    case 'objects.basestructure.id':
      return {
        highlighted: getIsHighlighted(colKey),
        key: colKey,
        kind: getFilterKind(colKey),
        operators: getOperators(colKey),
        title: 'Strukturobjekt',
        relationModelname: getRelationModelName(colKey),
      };
    case 'objects.basespace.source_identifier':
      return {
        highlighted: false,
        key: colKey,
        kind: getFilterKind(colKey),
        operators: getOperators(colKey),
        title: 'Typ av objekt',
        choices: [
          {
            v: 'objects.apartment',
            d: 'Lägenheter',
          },
          {
            v: 'objects.industrialpremises',
            d: 'Lokaler',
          },
          {
            v: 'objects.outdoorarea',
            d: 'Utomhusområden',
          },
          {
            v: 'objects.parkingspot',
            d: 'Fordonsplatser',
          },
          {
            v: 'objects.commonarea',
            d: 'Gemensamma utrymmen',
          },
        ],
      };
    case 'accounting.transaction.account':
      return {
        highlighted: false,
        key: colKey,
        kind: 'string' as IReportFilterKind,
        operators: getOperators(colKey),
        title: 'Konton',
        description: 'Kommaseparera för att filtrera på fler konton',
      };

    case 'contracts.basecontract_tags.referenced_by_tags': {
      return {
        highlighted: false,
        key: colKey,
        kind: 'relation' as IReportFilterKind,
        operators: ['in'] as IReportOperator[],
        title: 'Taggar på avtalet',
        relationModelname: 'tag' as ModelName,
      };
    }

    case 'contracts.basecontract.source_identifier': {
      return {
        highlighted: false,
        key: colKey,
        kind: 'multiselect' as IReportFilterKind,
        operators: ['in'] as IReportOperator[],
        title: 'Avtalstyper',
        choices: [
          {
            v: 'contracts.apartmentcontract',
            d: 'Lägenhetsavtal',
          },
          {
            v: 'contracts.industrialpremisescontract',
            d: 'Lokalavtal',
          },
          {
            v: 'contracts.parkingspotcontract',
            d: 'Parkeringsavtal',
          },
          {
            v: 'contracts.outdoorsectioncontract',
            d: 'Utomhussektionsavtal',
          },
          {
            v: 'contracts.othercontract',
            d: 'Övriga avtal',
          },
          {
            v: 'contracts.blockcontract',
            d: 'Blockavtal',
          },
          {
            v: 'brf.brfapartmentinvoiceconfiguration',
            d: 'BRF-avtal',
          },
        ],
      };
    }
    case 'accounting.invoice.invoice_number':
      return {
        highlighted: false,
        key: colKey,
        kind: 'boolean' as IReportFilterKind,
        operators: ['isnull'] as IReportOperator[],
        title: 'Enbart attesterade',
      };
    case 'accounts.tenant.realestates':
      return {
        highlighted: getIsHighlighted(colKey),
        key: colKey,
        kind: getFilterKind(colKey),
        operators: getOperators(colKey),
        title: title,
        relationModelname: getRelationModelName(colKey),
      };
    default:
      return false;
  }
};

const checkRemoveDueToSimilarReportFilter = (
  colKey: string,
  reportFilters: IReportOptions['available_report_filters'] = {}
) => {
  switch (colKey) {
    case 'organization.company.id':
      return reportFilters['company_ids'] != null;
    case 'objects.realestate.id':
      return reportFilters['realestate_ids'] != null;
    case 'objects.basespace.source_identifier':
      return reportFilters['object_types'] != null;

    default:
      return false;
  }
};
export const buildReportOptions = (
  reportConfig: IReportConfig
): IReportOptions => {
  const result: IReportOptions = {
    available_aggregate_ids: {},
    available_filters: [],
    available_group_by: {},
    available_aggregate_result: {},
    available_columns: {},
    available_report_filters: {},
  };

  const availableReportFilters = ObjectKeys(reportConfig.report_filters ?? {});

  if (availableReportFilters.length > 0) {
    if (availableReportFilters.includes('for_date')) {
      set(result, 'available_report_filters.for_date', {
        kind: 'date',
        title: 'Datum',
      });
    }

    if (availableReportFilters.includes('period_start')) {
      set(result, 'available_report_filters.period_start', {
        kind: 'date',
        title: 'Period från',
      });
    }
    if (availableReportFilters.includes('period_end')) {
      set(result, 'available_report_filters.period_end', {
        kind: 'date',
        title: 'Period till',
      });
    }

    if (availableReportFilters.includes('company_ids')) {
      set(result, 'available_report_filters.company_ids', {
        kind: 'relation',
        relationModelName: 'company',
        title: 'Bolag',
      });
    }
    if (availableReportFilters.includes('realestate_ids')) {
      set(result, 'available_report_filters.realestate_ids', {
        kind: 'relation',
        relationModelName: 'realestate',
        title: 'Fastigheter',
      });
    }
    if (availableReportFilters.includes('building_ids')) {
      set(result, 'available_report_filters.building_ids', {
        kind: 'relation',
        relationModelName: 'building',
        title: 'Byggnader',
      });
    }
    if (availableReportFilters.includes('outdoor_area_ids')) {
      set(result, 'available_report_filters.outdoor_area_ids', {
        kind: 'relation',
        relationModelName: 'outdoorarea',
        title: 'Utomhusområden',
      });
    }
    if (availableReportFilters.includes('parking_lot_ids')) {
      set(result, 'available_report_filters.parking_lot_ids', {
        kind: 'relation',
        relationModelName: 'parkinglot',
        title: 'Parkeringsområden',
      });
    }

    if (availableReportFilters.includes('rental_invoices_filter')) {
      set(result, 'available_report_filters.rental_invoices_filter', {
        kind: 'select',
        title: 'Inkludera',
        choices: [
          {
            v: null,
            d: 'Både avier och fakturor',
          },
          {
            v: false,
            d: 'Enbart fakturor',
          },
          {
            v: true,
            d: 'Enbart avier',
          },
        ],
      });
    }

    if (availableReportFilters.includes('invoiceable_types')) {
      set(result, 'available_report_filters.invoiceable_types', {
        kind: 'multiselect',
        title: 'Avtalstyper',
        choices: [
          {
            v: 'contracts.apartmentcontract',
            d: 'Lägenhetsavtal',
          },
          {
            v: 'contracts.industrialpremisescontract',
            d: 'Lokalavtal',
          },
          {
            v: 'contracts.parkingspotcontract',
            d: 'Parkeringsavtal',
          },
          {
            v: 'contracts.outdoorsectioncontract',
            d: 'Utomhussektionsavtal',
          },
          {
            v: 'contracts.othercontract',
            d: 'Övriga avtal',
          },
          {
            v: 'contracts.blockcontract',
            d: 'Blockavtal',
          },
          {
            v: 'brf.brfapartmentinvoiceconfiguration',
            d: 'BRF-avtal',
          },
        ],
      });
    }

    if (availableReportFilters.includes('include_unattested_invoices')) {
      set(result, 'available_report_filters.include_unattested_invoices', {
        kind: 'boolean',
        title: 'Inkludera ej attesterade',
      });
    }
    if (availableReportFilters.includes('include_private_tenants')) {
      set(result, 'available_report_filters.include_private_tenants', {
        kind: 'boolean',
        title: 'Inkludera privathyregäster',
      });
    }
    if (availableReportFilters.includes('include_corporate_tenants')) {
      set(result, 'available_report_filters.include_corporate_tenants', {
        kind: 'boolean',
        title: 'Inkludera bolagshyresgäster',
      });
    }
    if (availableReportFilters.includes('ignore_co_invoicing_settings')) {
      set(result, 'available_report_filters.ignore_co_invoicing_settings', {
        kind: 'boolean',
        title: 'Ignorera samaviseringsinställningar',
      });
    }
    if (availableReportFilters.includes('ignore_automatic_creation_paused')) {
      set(result, 'available_report_filters.ignore_automatic_creation_paused', {
        kind: 'boolean',
        title: 'Ignorera pausad automatisk avisering',
      });
    }

    if (availableReportFilters.includes('object_types')) {
      set(result, 'available_report_filters.object_types', {
        kind: 'multiselect',
        title: 'Objekttyp',
        choices: [
          {
            v: 'objects.apartment',
            d: 'Lägenheter',
          },
          {
            v: 'objects.industrialpremises',
            d: 'Lokaler',
          },
          {
            v: 'objects.parkingspot',
            d: 'Fordonsplatser',
          },
          {
            v: 'objects.outdoorsection',
            d: 'Utomhussektioner',
          },
        ],
      });
    }
  }

  ObjectKeys(reportConfig.sheets ?? {}).forEach((sheetKey) => {
    const currentSheet = reportConfig.sheets[sheetKey];

    result.available_columns[sheetKey.toString()] = [];
    result.available_group_by[sheetKey.toString()] = [];
    result.available_aggregate_result[sheetKey.toString()] = [];
    result.available_aggregate_ids[sheetKey.toString()] = [];

    const columns = currentSheet.columns;

    ObjectKeys(columns).forEach((colKey) => {
      const currentColumn = columns[colKey];

      result.available_columns[sheetKey.toString()].push({
        title: currentColumn.title,
        sectionTitle: currentColumn.section_title,
        key: colKey.toString(),
      });

      // get available group by
      if (
        currentColumn.group_by &&
        !result.available_group_by[sheetKey.toString()].find(
          (g) => g.key === colKey.toString()
        )
      ) {
        result.available_group_by[sheetKey.toString()].push({
          key: colKey.toString(),
          title: currentColumn.title,
          sectionTitle: currentColumn.section_title,
        });
      }
      // get available aggregate ids
      if (
        currentColumn.aggregation_id &&
        !result.available_aggregate_ids[sheetKey.toString()].find(
          (g) => g.key === colKey.toString()
        )
      ) {
        result.available_aggregate_ids[sheetKey.toString()].push({
          key: colKey.toString(),
          title: currentColumn.title,
          sectionTitle: currentColumn.section_title,
        });
      }
      // get available aggregate result
      if (
        currentColumn.aggregation_result &&
        !result.available_aggregate_result[sheetKey.toString()].find(
          (g) => g.key === colKey.toString()
        )
      ) {
        result.available_aggregate_result[sheetKey.toString()].push({
          key: colKey.toString(),
          title: currentColumn.title,
        });
      }

      const filterForColumn = getColumnFilter(
        colKey.toString(),
        currentColumn.title
      );

      // due to the nature of the data from the backend sometimes filters are duplicated
      const duplicateFilter =
        filterForColumn &&
        (!!result.available_filters.find((f) => f.key === colKey) ||
          checkRemoveDueToSimilarReportFilter(
            colKey.toString(),
            result.available_report_filters
          ));

      if (filterForColumn && !duplicateFilter) {
        result.available_filters.push(filterForColumn);
      }
    });
  });

  return result;
};

export const zipAndDownload = async ({
  downloadUrl,
  fileEnding,
  fileName,
}: {
  downloadUrl: string;
  fileEnding: string;
  fileName?: string;
}) => {
  const zip = new JSZip();

  const fileData = await fetch(downloadUrl);
  const blob = await fileData.blob();
  zip.file(`${fileName ?? 'download'}.${fileEnding}`, blob);

  const zipped = await zip.generateAsync({ type: 'blob' });

  const url = window.URL.createObjectURL(zipped);
  const a = document.createElement('a');
  a.target = '_blank';
  a.href = url;

  a.download = `${fileName ?? 'download'}.zip`;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};

export const downloadFile = async ({
  downloadUrl,
  fileEnding,
  fileName,
}: {
  downloadUrl: string;
  fileEnding: string;
  fileName?: string;
}) => {
  const fileData = await fetch(downloadUrl);
  const blob = await fileData.blob();

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.target = '_blank';
  a.href = url;

  a.download = `${fileName ?? 'download'}.${fileEnding}`;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};

export const transformBody = ({
  externalName,
  formData,
  report,
}: {
  externalName: IReportTypes;
  formData: IReportBody;
  report: IReportConfig;
}) => {
  let transformedBody: IReportBody = formData;

  // first clean empty filters
  transformedBody = cleanEmptyFilters(transformedBody);

  // run transformations for specific reports
  switch (externalName) {
    case 'verifications':
      transformedBody = transformVerificationsBody(formData);
      break;
    case 'invoices':
      transformedBody = transformInvoicesBody(formData);
      break;
    default:
      break;
  }

  // add applied filters to all sheets where the filtered key exists
  transformedBody = addAppliedFiltersToAllSheets({
    formData: transformedBody,
    report,
  });

  // add organization filter
  transformedBody = addOrganizationFilter({
    externalName,
    formData: transformedBody,
    report,
  });

  return transformedBody;
};

const cleanEmptyFilters = (formData: IReportBody) => {
  ObjectKeys(formData.sheet_configurations ?? {}).forEach((sheetKey) => {
    const cur = formData.sheet_configurations[sheetKey];

    if (cur.aggregation_operations?.length === 0) {
      formData.sheet_configurations[sheetKey].aggregation_operations =
        undefined;
    }
    if (cur.filters?.length === 0) {
      formData.sheet_configurations[sheetKey].filters = undefined;
    }
    if (cur.group_by?.length === 0) {
      formData.sheet_configurations[sheetKey].group_by = undefined;
    }
    if (cur.exclude_columns?.length === 0) {
      formData.sheet_configurations[sheetKey].exclude_columns = undefined;
    }
  });

  ObjectKeys(formData.report_filters ?? {}).forEach((filterKey) => {
    if (
      formData.report_filters &&
      Array.isArray(formData.report_filters[filterKey]) &&
      formData.report_filters[filterKey].length === 0
    ) {
      formData.report_filters[filterKey] = null;
    }
  });

  return formData;
};

const transformInvoicesBody = (formData: IReportBody) => {
  Object.entries(formData.sheet_configurations ?? {}).forEach(
    ([sheetKey, config]) => {
      if (config.filters) {
        formData.sheet_configurations[sheetKey].filters = config.filters.map(
          (filter) => {
            if (filter?.column === 'accounting.invoice.invoice_number') {
              return {
                ...filter,
                value: !filter.value,
                operator: 'isnull',
              };
            }
            return filter;
          }
        );
      }
    }
  );

  return formData;
};

const transformVerificationsBody = (formData: IReportBody) => {
  if (formData.sheet_configurations?.verifications?.filters) {
    formData.sheet_configurations.verifications.filters =
      formData.sheet_configurations.verifications.filters.map((filter) => {
        if (
          filter?.column === 'accounting.transaction.account' &&
          Array.isArray(filter.value)
        ) {
          return {
            ...filter,
            value: filter.value.map((v) => parseInt(v.toString())),
          };
        }
        return filter;
      });
  }

  return formData;
};

const addAppliedFiltersToAllSheets = ({
  formData,
  report,
}: {
  formData: IReportBody;
  report: IReportConfig;
}) => {
  // Get all unique filters across all sheets
  const allFilters = new Map<
    string,
    { value: any; operator: IReportOperator }
  >();

  // First collect all filters from all sheets
  Object.entries(formData.sheet_configurations).forEach(([, config]) => {
    config.filters?.forEach((filter) => {
      allFilters.set(filter.column, {
        value: filter.value,
        operator: filter.operator,
      });
    });
  });

  // Then apply filters to all sheets that have the column
  Object.entries(formData.sheet_configurations).forEach(
    ([sheetKey, config]) => {
      const sheetColumns = report.sheets[sheetKey]?.columns ?? {};

      allFilters.forEach((filterValue, column) => {
        // Only add filter if column exists in this sheet
        if (Object.keys(sheetColumns).includes(column)) {
          if (!config.filters) {
            config.filters = [];
          }

          // Don't add duplicate filters
          if (!config.filters.find((f) => f.column === column)) {
            config.filters.push({
              column,
              value: filterValue.value,
              operator: filterValue.operator,
            });
          }
        }
      });
    }
  );

  return formData;
};

const addOrganizationFilter = ({
  externalName,
  formData,
  report,
}: {
  externalName: IReportTypes;
  formData: IReportBody;
  report: IReportConfig;
}) => {
  const config = AVAILABLE_REPORTS.find((r) => r.externalName === externalName);

  if (
    config?.organizationFilterTargetKeys &&
    config.organizationFilterTargetKeys.length > 0
  ) {
    // Add organization filter for each target key to each sheet
    Object.keys(formData.sheet_configurations).forEach((sheetKey) => {
      config.organizationFilterTargetKeys.forEach((targetKey) => {
        // Check if the target key exists in the sheet's columns from the report config
        const sheetColumns = report.sheets[sheetKey]?.columns ?? {};
        const columnExists = Object.keys(sheetColumns).includes(targetKey);

        // Only add filter if the column exists in the sheet
        const organizationId = getCookie('organization_id');
        if (columnExists && !!organizationId) {
          const sheetConfig = formData.sheet_configurations[sheetKey];
          if (!sheetConfig.filters) {
            sheetConfig.filters = [
              {
                column: targetKey,
                value: [organizationId],
                operator: 'in',
              },
            ];
          } else if (!sheetConfig.filters.find((f) => f.column === targetKey)) {
            sheetConfig.filters.push({
              column: targetKey,
              value: [organizationId],
              operator: 'in',
            });
          }
        }
      });
    });
  }

  return formData;
};
